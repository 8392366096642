import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

export default function Contact() {
  return (
    <Layout title="404" short>
      <PageHeader
        label="404 error"
        headline="Page not found"
        subhead={
          <div className="max-w-lg mx-auto">
            Sorry, the requested page could not be found.
          </div>
        }
      />
    </Layout>
  );
}
